//import { reactive, readonly } from "vue";
import { ref } from 'vue'

const unlocked = ref(false);
 
const toggleTimer = () => {
    unlocked.value = true;
    sessionStorage.setItem('unlocked', true);
}

if(sessionStorage.unlocked){
  //toggleTimer()
}

export default { unlocked, toggleTimer };