<template>
  <header class="header-wrapper">
    <nav class="site-header wrapper">
      <router-link
        :to="{ name: 'home' }"
        class="logo text_size_large link_decoration_none text_type_display"
        ><img class="fav" src="@/assets/images/favicon.svg" /></router-link
      >      
    </nav>
  </header>
</template>

<script>
export default {}
</script>

<style scoped>
.logo{
  display: flex;
  align-items: center;  
  
}
.fav{
  height: calc(1.5 * (1.6rem + (24 - 16) * ((100vw - 300px) / (1600 - 300))));
  padding-top: 0.4rem;
  
}
.site-header{
  height: calc((2*(1.6rem + (24 - 16) * ((100vw - 300px) / (1600 - 300)))) + var(--padding-sm));
  position: sticky;
  top: 0rem; 
  background: var(--bg-color);
  display: flex;
}
.header-wrapper{
  background-color: #fffffffc;
  position: sticky;  
  z-index: 1;
  top: -1.8rem;
  height: calc((2*(1.6rem + (24 - 16) * ((100vw - 30rem) / (1600 - 300)))) + var(--padding-sm) + 0.4rem);   
}
.header-wrapper::before,
.header-wrapper::after{
  content: "";
  display: block; 
  position: sticky;
  height: 4px;
}
.header-wrapper::before{
  top:calc((2*(1.6rem + (24 - 16) * ((100vw - 30rem) / (1600 - 300)))) + var(--padding-sm) - 0.4rem);  
  width: 100%; 
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0,0,0, 0.15);
}
.header-wrapper::after{  
  background: linear-gradient(var(--bg-color) 10%, transparent 10%);
  top:0;
  z-index: 2;
}

</style>
