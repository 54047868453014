export const Pages = {
    portfolio: [
        {
            type: 'experience',
            slug:'vrslcm',
            details:{
                label: "Enterprise Product",
                title: "vRealize Suite Lifecycle Manager",
                company: "VMware",
                dates: "2020-present"
            }
        },
        {
            type: 'experience',
            slug:'bixby',
            details:{
                label: "VUI & GUI App",
                title: "Bixby Voice Platform",
                company: "Samsung",
                dates: "2016-2020"
            }
        },
       
    ],
    projects: [
        {
            type: 'older',
            slug:'cpfa',
            details:{            
                title: "Visualizing Social Interactions at Central Pennsylvania Art Festival"           
            }
        },
        {
            type: 'older',
            slug:'ehr',
            details:{            
                title: "Digital Modeling of Health Information"           
            }
        },
        {
            type: 'older',
            slug:'tangify',
            details:{            
                title: "Tangible Learning Toolkit for Children with Intellectual Disabilities"           
            }
        },
    ]
}
export const Others = [
    {
        type: 'other',
        label: 'Art, Design & Code',
        key: 'co-cur',
        details:{            
            title: "Side Projects",
            link: "https://poulamic.com/"      
        }
    },
    {
        type: 'other',
        label: 'Writings on Design',
        key: 'medium',
        details:{            
            title: "Blog",
            link: "https://medium.com/@Poulami"      
        }
    },
    
]
export const Writings = [
    {
        title: 'Designing for information density',
        link: 'https://uxdesign.cc/designing-for-information-density-69775165a18e'
    },
    {
        title: 'Thinking in frameworks: communicating system status',
        link: 'https://uxdesign.cc/thinking-in-frameworks-communicating-system-status-eaf6f7b58bab'
    },
    {
        title: 'Design principles to revisit for Voice UX',
        link: 'https://uxdesign.cc/design-principles-to-revisit-for-voice-ux-design-5a8b6fd0e8ed'
    }
]