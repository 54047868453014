import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import CurHome from '../views/CurHome.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'status',
    component: CurHome    
  },
  {
    path: '/portfolio/:project',
    name: 'portfolio',    
    component: () => import(/* webpackChunkName: "project" */ '../views/WorkExperience.vue'),
    props: true
  },
  {
    path: '/projects/:project',
    name: 'projects',    
    component: () => import(/* webpackChunkName: "project" */ '../views/OldProjects.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      //return savedPosition
    }
    else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        //offset: { x: 0, y: 400 },
      }
    }else{
      return { x: 0, y: 0 }
      //document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }    
  },
})


export default router
