<template>
  <div id="app">
    <navigation-bar />
    <router-view />
    <site-footer />
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import SiteFooter from './components/SiteFooter.vue'
export default {
  components: { NavigationBar, SiteFooter },
}
</script>

<style>
@import './css/style.css';

</style>
