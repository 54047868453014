<template>
  <div class=" wrapper wrapper_blank">
      <div class="update_message">
      <h3 class="header-article-1">Be Back Soon!</h3>
      <p>Currently working on updating this space. Till then, find me on <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/poulamichakraborty/" class="contact-item">LinkedIn</a>.</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .wrapper_blank{
        min-height: 75vh;
        display: grid;
        place-items: center;
    }
    .update_message{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .update_message>*{        
        text-align: center;
    }
</style>