<template>
  <footer class="site-footer wrapper">
    <p class="text-small">Design+Code@Poulami {{ year }}</p>
  </footer>
</template>

<script>
export default {
  computed: {
    year: () => {
      let today = new Date()
      return today.getFullYear()
    },
  },
}
</script>

<style>
.footer {
  padding-bottom: calc(1 * var(--padding-lg));
}
</style>
