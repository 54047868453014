<template>
  <div class="about">
    <aside class="wrapper">
      <ul class ="contacts">          
          <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/poulamichakraborty/" class="contact-item">LinkedIn</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://poulamic.medium.com/" class="contact-item">Medium</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/poulamic_" class="contact-item">Twitter</a></li>
        </ul>
    </aside>
    <main class="main wrapper">
      <section class="section_page section-3">
        <h1 class="p-lead">Hi! I am Poulami Chakraborty, an end‑to‑end digital product experience designer and a business-focussed UX strategist.</h1>
        <p>I enjoy and specialize in translating complex problems and processes into simple, intuitive and scalable experiences which work for both users and businesses. I am passionate about exploring new mediums and industries where design can empower people to do their best work.</p>
        <p>Backed by a formal degree in design, 5+ years of experience in consumer and enterprise UX, and coding knowledge, I bring practical innovation through intersection of design and technology.</p>
        <p>Currently @VMware. Previously @Samsung.</p>
      </section>
      <div class="section_page section-2">
        <section class="section_page">
          <h2>Work</h2>
          <section class="card-grid work-grid">
            <router-link
              v-for="exp in experience"
              :key="exp.slug"
              :to="{ name: 'portfolio', params: { project: exp.slug}}"              
              class="card card-link"              
            >      
              <img :src="require(`@/assets/images/thumbnails/${exp.slug}.png`)" alt="project thumbnail">
              <div class ="card-details">
                <div>
                  <p class="text-small">{{exp.details.label}}</p>
                  <p class="text-mid card-title">{{exp.details.title}}</p>
                </div>
                <div>
                  <p class="text-small">{{exp.details.company}}</p>
                  <p class="text-small">{{exp.details.dates}}</p>
                </div>
              </div>
            </router-link> 
          </section>
          </section>
        <section class="section_page">
          <h2>On the side</h2>
          <section class="card-grid other-grid">            
            <a target="_blank" rel="noopener noreferrer" v-for="other in others" :href="other.details.link" :key="other.details.title" class="card card-link">
              <img :src="require(`@/assets/images/thumbnails/${other.key}.png`)" alt="decorative thumbnail">
              <div class ="card-details">
                <div>
                    <p class="text-small">{{other.label}}</p>
                    <p class="text-mid card-title">{{other.details.title}}</p>
                  </div>
              </div>
            </a>
          </section>
        </section>
        <section class="section_page">
          <h2>Selected Writings</h2>
          <div class="section_inner">
            <a target="_blank" class="text-body" v-for="writing in writings" :key= "writing.title" :href="writing.link" rel="noopener noreferrer">{{writing.title}}</a> 
          </div>                    
        </section>
        <section class="section_page">
          <div>
            <h2>Older case studies</h2>
          </div>
          <div class="section_inner">
            <p>These are some college and internship projects which equipped me with core design craft.</p>
          </div>
          <div class="section_inner">
            <router-link
                  v-for="acad in acads"
                  :key="acad.slug"
                  :to="{ name: 'projects', params: { project: acad.slug, projectType: acad.type }}"
                  
                  class="text-body"              
            >{{acad.details.title}}
            </router-link>
          </div>           
      </section>
      </div>
    </main>
  </div>
</template>
<script>
  import { Pages, Others, Writings } from "@/content/index.js";
  export default {
    data() {
      return {
        experience: Pages.portfolio,
        others: Others,
        acads: Pages.projects,
        writings: Writings
      };
    }
  };
</script>


